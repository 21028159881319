<template>
  <section aria-labelledby="quick-links-title">
    <div class="p-6">
      <div class="md:flex md:items-center md:justify-between">
        <h2 class="text-xl font-bold text-gray-900 sm:text-2xl">Wallets</h2>
      </div>
      <p class="mt-2 text-sm text-gray-500">
        Link multiple wallets to your account to view their tokens and use them to sign in. Swap between wallets using the wallet switcher in the sidebar.
      </p>
      <div class="mt-5 space-x-4">
        <DBtn secondary color="blue" icon="metamask" :loading="loading" @click="connect('ETHEREUM')">
          Metamask
        </DBtn>
        <DBtn secondary color="blue" icon="phantom" :loading="loading" @click="connect('SOLANA')">
          Phantom
        </DBtn>
      </div>
    </div>
    <table class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th scope="col" class="pl-6 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Chain
            </th>
            <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Address
            </th>
            <th scope="col" class="pr-6 pl-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Connected
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-100">
          <tr v-for="wallet in wallets" :key="wallet.id">
            <td class="pl-6 pr-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {{ wallet.chain.humanName }}
            </td>
            <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ wallet.address }}
            </td>
            <td class="pr-6 pl-4 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ $filters.moment(wallet.verifiedAt).fromNow() }}
            </td>
          </tr>
        </tbody>
      </table>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { ownProfile } from '@/gql/auth'
import { useQuery, useResult } from '@vue/apollo-composable'
import { notify } from '@kyvg/vue3-notification'
import { DBtn, MetamaskIcon, PhantomIcon } from '@ui'

const store = useStore()
const id = store.getters['auth/id']

const { result, loading, refetch } = useQuery(ownProfile, { id })
const wallets = useResult(result, [], ({ profile }) => profile.wallets)

const connect = async (chainName) => {
  try {
    await store.dispatch('auth/loginWithWeb3', chainName)
    await refetch({ id })
  } catch (err) {
    // Show message about error logging in
    notify({ type: 'error', title: 'Unexpected error', text: err.message })
  }
}
</script>